import { monthYear, yearMonthDay } from '@/helpers/dateFormats'
import dayjs from 'dayjs'
import MtApi from '../../agGridV2/helpers/mt-api.helper'
export default {
  state: {
    showCharts: false,
    initDateRange: {
      start: null,
      end: null
    },
    dateRange: {
      start: null,
      end: null
    },
    platforms: [],
    selectedPlatform: null
  },
  getters: {
    getLeadCenterDate: (state) => {
      return state.dateRange
    },
    getShowChartsLeadCenter(state) {
      return state.showCharts
    },
    getPlatforms(state) {
      return state.platforms
    },
    getSelectedPlatform(state) {
      return state.selectedPlatform
    },
    getInitDateRange(state) {
      return state.initDateRange
    }
  },
  mutations: {
    setLeadCenterDateStart(state, value) {
      state.dateRange.start = value
    },
    setLeadCenterDateEnd(state, value) {
      state.dateRange.end = value
    },
    setShowChartsLeadCenter(state, value) {
      state.showCharts = value
    },
    setPlatforms(state, platforms) {
      state.platforms = platforms
    },
    setSelectedPlatform(state, platform) {
      state.selectedPlatform = platform
    },
    setInitDateRange(state, dateRange) {
      state.initDateRange = dateRange
    },
    updatePlatformInArray(state, updatedPlatform) {
      const index = state.platforms.findIndex(
        (platform) => platform._id === updatedPlatform._id
      )
      if (index !== -1) {
        state.platforms.splice(index, 1, updatedPlatform)
      }
    }
  },
  actions: {
    async loadAndSetDefaultLeadCenterDate({ commit, state, getters }) {
      if (!getters.me) {
        return false
      }
      const dates = await MtApi.getStartEndDates({ platform_id: state.selectedPlatform._id })
      commit('setInitDateRange', dates)
      if (dates.start) {
        if (!state.dateRange.start || !state.dateRange.end) {
          if (!state.dateRange.start) {
            commit('setLeadCenterDateStart', {
              label: dayjs(dates.start).format(monthYear),
              code: dayjs(dates.start).startOf('month').format(yearMonthDay)
            })
          }
          if (!state.dateRange.end) {
            commit('setLeadCenterDateEnd', {
              label: dayjs(dates.end).format(monthYear),
              code: dayjs(dates.end).endOf('month').format(yearMonthDay)
            })
          }
        }
      } else {
        commit('setLeadCenterDateStart', null)
        commit('setLeadCenterDateEnd', null)
      }
    },
    async loadAndSetPlatforms({ commit, state, getters }) {
      if (!getters.me) {
        return false
      }
      try {
        const platforms = [
          {
            _id: 'all',
            url: 'All',
            setup: true
          },
          ...(await MtApi.getPlatforms({ select: '_id url setup' }))
        ]
        commit('setPlatforms', platforms)
        if (state.selectedPlatform) {
          const updatedSelectedPlatform = platforms.find( p => p._id === state.selectedPlatform._id )
          if (updatedSelectedPlatform) {
            commit('setSelectedPlatform', updatedSelectedPlatform)
          } else if (platforms.length > 0) {
            commit('setSelectedPlatform', platforms[0])
          }
        } else if (platforms.length > 0) {
          commit('setSelectedPlatform', platforms[0])
        }
      } catch (error) {
        console.error('Ошибка при загрузке платформ:', error)
      }
    },
    async updateAndSetPlatform({ commit, state, getters }, data) {
      if (!getters.me) {
        return false
      }
      try {
        commit('setSelectedPlatform', state.selectedPlatform)
        commit('updatePlatformInArray', state.selectedPlatform)
      } catch (error) {
        console.error('Ошибка при загрузке платформы:', error)
      }
    }
  }
}
