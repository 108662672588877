export default {
  state: {
    pftChartsSeriesVisible: {},
    expChartsSeriesVisible: {}
  },
  mutations: {
    setPftChartSeriesVisible(state, { key, value }) {
      state.pftChartsSeriesVisible[key] = value
    },
    setExpChartSeriesVisible(state, { key, value }) {
      state.expChartsSeriesVisible[key] = value
    }
  },
  getters: {
    getPftChartSeriesVisible: (state) => (key) => {
      return key in state.pftChartsSeriesVisible ? state.pftChartsSeriesVisible[key] : false
    },
    getExpChartSeriesVisible: (state) => (key) => {
      return key in state.expChartsSeriesVisible ? state.expChartsSeriesVisible[key] : true
    }
  }
}
