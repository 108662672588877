import Snackbar from 'node-snackbar'

export function showSnackbar(settings) {
  const modes = new Map([
    ['error', '#D30000'],
    ['success', 'green']
  ])
  Snackbar.show({
    pos: 'top-right',
    showAction: false,
    textColor: '#fff',
    backgroundColor: modes.get(settings.mode),
    text: settings.text
  })
}
